import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { ItemWide } from 'app/components/Mosaic/ItemWide'
import { chunk } from 'lodash'
import { uniq } from 'lodash'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  cta?: ButtonProps[]
  items: ItemProps[]
  label?: string
  title?: string
}

export const Mosaic = memo(function Mosaic({
  cta,
  items,
  label,
  title,
}: Props) {
  if (items.length < 1) {
    return null
  }

  const lastItem = items.slice(-1)[0]
  const chunkedItems = chunk(items.slice(0, -1), 3)

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      {label ? <Label>{label}</Label> : null}
      <Fade bottom distance="3.75rem">
        <>
          <List>
            {chunkedItems.map((items, index) => (
              <ItemsWrap key={index} row wrap>
                {uniq(items).map((item, index) => (
                  <Item key={index} {...item} />
                ))}
              </ItemsWrap>
            ))}
            <ItemWide {...lastItem} />
          </List>
          {cta
            ? cta.map((item, index) => (
                <CTA key={index} {...item} variant="corners" />
              ))
            : null}
        </>
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  margin: 6rem auto;
  padding: 0 15.5vw;
  text-align: center;

  @media (max-width: 1399px) {
    padding: 0 11.5vw;
  }

  @media (max-width: 1199px) {
    padding: 0 1.9375rem;
  }

  @media (max-width: 1023px) {
    margin: 3.75rem auto;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.4375rem;
  font-weight: 500;

  @media (max-width: 1023px) {
    font-size: 25px;
  }
`

const Label = styled.div`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 0.9375rem;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
`

const List = styled.div`
  margin-top: 4.125rem;
`

const ItemsWrap = styled(FlexBox)`
  position: relative;
  &:nth-of-type(2n) {
    > div {
      &:first-of-type {
        order: 3;
      }
      &:nth-of-type(3) {
        right: auto;
        left: 0;
      }
    }
  }
`

const CTA = styled(Button)`
  margin-top: 3.4375rem;

  @media (max-width: 1023px) {
    margin-top: 3.4375rem;
  }
`
