import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { uniq } from 'lodash'
import React, { memo } from 'react'
import { ArrowDown } from 'react-feather'

import { Item, Props as BenefitsItem } from './Item'

export interface Props {
  benefits?: BenefitsItem[]
  description?: string
  IBEID?: number
  languageCode: string
  languagePrefix: string
}

export const Benefits = memo(function Benefits({
  benefits,
  description,
  IBEID,
  languageCode,
  languagePrefix,
}: Props) {
  if (benefits && benefits?.length < 1) {
    return null
  }

  return (
    <Container>
      <CTA
        href={`https://be.bookingexpert.it/book/simple/step1?hotel=${IBEID}&lang=${
          languagePrefix ? languagePrefix : 'it'
        }`}
        rel="noopener"
        target="_blank"
      >
        <ArrowDown />
        {useVocabularyData('book-and-save', languageCode)}
      </CTA>
      <Wrapper row space="between" wrap>
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
        <Checklist dial={2} row wrap>
          {uniq(benefits)?.map((item, index) => (
            <Item key={index} {...item} />
          ))}
        </Checklist>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: -5.25rem auto 0;
  padding: 0 15.5vw;
  position: relative;
  z-index: 2;

  @media (max-width: 1700px) {
    max-width: 75.75rem;
    padding: 0 1.5rem;
  }

  @media (max-width: 767px) {
    margin-top: 0;
    padding: 0;
  }
`

const CTA = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 22.5rem;
  height: 5.25rem;
  background: #54a78b;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 1.125rem;
  padding: 0 1.5rem;
  text-transform: uppercase;

  svg {
    width: auto;
    height: 1.3125rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin-right: 10px;
  }

  @media (max-width: 1280px) {
    width: 18.75rem;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const Wrapper = styled(FlexBox)`
  position: relative;
  &:before {
    content: '';
    width: calc(100% - 11.25rem);
    height: 100%;
    border: 3px solid #edf4f1;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  @media (max-width: 1280px) {
    &:before {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    &:before {
      border-top: 0;
      border-right: 0;
      border-left: 0;
    }
  }
`

const Description = styled.div`
  width: 22.5rem;
  background: #edf4f1;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 2rem;
  padding: 2.9375rem 3.875rem 3.5625rem;
  b,
  strong {
    color: #54a78b;
  }

  @media (max-width: 1280px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    padding: 1.85rem 1.5rem;
  }
`

const Checklist = styled(FlexBox)`
  width: calc(100% - 22.5rem);
  padding: 4.625rem 0 4.875rem;

  @media (max-width: 1280px) {
    width: 100%;
    justify-content: center;
    padding: 3.25rem 0;
  }
`
