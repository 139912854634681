import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  className?: string
  dateCreated: string
  image?: ImageProps
  label?: string
  languageCode: string
  title: string
  URL: string
}

export const Event = memo(function Event({
  className,
  image,
  languageCode,
  title,
  URL,
}: Props) {
  return (
    <Container className={className} to={URL}>
      {image ? <LazyLoadImage {...image} /> : null}
      <Wrapper className="event-wrap" dial={5}>
        <Title>{title}</Title>
        <CTA
          className="event-cta"
          label={useVocabularyData('discover-details', languageCode)}
          noActions
        />
      </Wrapper>
    </Container>
  )
})

const Container = styled(Link)`
  height: 25.417vw;
  background: #54a78b;
  overflow: hidden;
  position: relative;
  &:hover {
    &:before {
      opacity: 0.6;
    }
    img {
      transform: scale(1.1);
    }
    .event-wrap {
      &:before {
        opacity: 1;
      }
    }
    .event-cta {
      opacity: 1;
      transform: translateY(0);
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
    backface-visibility: hidden;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out;
  }

  @media (max-width: 1199px) {
    padding-bottom: 57%;
  }

  @media (max-width: 767px) {
    padding-bottom: 70%;
  }

  @media (max-width: 480px) {
    padding-bottom: 80%;
  }
`

const Wrapper = styled(FlexBox)`
  padding: 2.5625rem;
  position: absolute;
  top: 2.75rem;
  right: 2.75rem;
  bottom: 2.75rem;
  left: 2.75rem;
  text-align: center;
  z-index: 3;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: #54a78b;
    backface-visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 1.375rem;
  text-align: center;
  position: relative;
  z-index: 2;
`

const CTA = styled(Button)`
  display: block;
  background: none;
  position: absolute;
  opacity: 0;
  bottom: 2.625rem;
  right: 0;
  left: 0;
  transform: translateY(1.5rem);
  transition: 0.2s ease-in-out;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-align: center;
  text-decoration: underline;
  text-underline-offset: 5px;

  &:hover {
    background: none;
  }

  @media (max-width: 1439px) {
    display: none;
  }
`
