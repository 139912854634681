import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import LazyLoad from 'react-lazyload'

export interface Props {
  image?: string
  label?: string
  title?: string
}

export const ItemWide = memo(function ItemWide({ image, label, title }: Props) {
  return (
    <Container row>
      <Wrap dial={5}>
        {title ? (
          <Text>
            {label ? <Label>{label}</Label> : null}
            <Title>{title}</Title>
          </Text>
        ) : null}
      </Wrap>
      <Background>
        <LazyLoad>
          <Inner style={{ backgroundImage: `url(${image})` }} />
        </LazyLoad>
      </Background>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  grid-column: 1;
  grid-row: 1;
  background: #53a78b;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  text-align: left;

  @media (max-width: 767px) {
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
  }
`

const Wrap = styled(FlexBox)`
  width: 25%;
  padding-bottom: 25%;
  order: 1;

  @media (max-width: 767px) {
    width: 50%;
    padding-bottom: 50%;
    order: 2;
  }
`

const Text = styled.div`
  padding: 0 3.75rem;
  position: absolute;
  top: 50%;
  left: auto;
  transform: translateY(-50%);
`

const Label = styled.div`
  margin-bottom: 0.5rem;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media (max-width: 767px) {
    margin-bottom: 0.375rem;
    font-size: 9px;
  }
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 500;
  font-size: 1.8125rem;

  @media (max-width: 1023px) {
    font-size: 18px;
  }
`

const Background = styled.div`
  width: 75%;
  padding-bottom: 25%;
  position: relative;
  order: 2;

  &:before {
    content: '';
    height: 2px;
    width: 25%;
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
    position: absolute;
    top: 50%;
    right: 50%;
    left: -2.25rem;
    z-index: 2;
    transform: translateY(-50%);
  }

  @media (max-width: 1023px) {
    &:before {
      left: -1.5rem;
    }
  }

  @media (max-width: 767px) {
    width: 50%;
    padding-bottom: 50%;
    order: 1;

    &:before {
      display: none;
    }
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.variants.neutralLight3};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
`
