import 'keen-slider/keen-slider.min.css'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Button } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo } from 'react'

import { Event, Props as EventProps } from './Event'

export interface Props {
  buttonURL?: string
  description?: string
  languageCode: string
  languagePrefix?: string
  events: EventProps[]
  title?: string
  variant?: Variant
}

export const EventsSlider = memo(function EventsSlider({
  buttonURL,
  description,
  languageCode,
  languagePrefix,
  events,
  title,
  variant = '3items',
}: Props) {
  if (events.length < 1) {
    return null
  }

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    autoAdjustSlidesPerView: false,
    loop: events.length > 2 ? true : false,
    slidesPerView: variant === '2items' ? 2 : 3,
    spacing: 16,
    breakpoints: {
      '(max-width: 1199px)': {
        centered: true,
        loop: events.length > 1 ? true : false,
        slidesPerView: 1.25,
        spacing: 16,
      },
    },
  })

  return (
    <Container variant={variant}>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}
      <Wrapper>
        <FadeInUp>
          <Slider
            className="keen-slider"
            data-keen-slider-pe="true"
            ref={sliderRef}
          >
            {events
              .sort((a: any, b: any) => a.sortOrder - b.sortOrder)
              .map((item, index) => (
                <Event className="keen-slider__slide" key={index} {...item} />
              ))}
          </Slider>
          <Arrows row space="between">
            <Arrow onClick={(e) => e.stopPropagation() || slider.prev()} />
            <Arrow
              direction="R"
              onClick={(e) => e.stopPropagation() || slider.next()}
            />
          </Arrows>
        </FadeInUp>
      </Wrapper>
      {buttonURL ? (
        <FadeInUp>
          <CTA
            label={useVocabularyData('all-events', languageCode)}
            URL={buttonURL}
            variant="corners"
          />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.section<ContainerProps>`
  margin: 6.25rem auto 7.5rem;
  padding: 3.4375rem 11.5vw 4.6875rem;
  position: relative;
  text-align: center;

  &:before {
    content: '';
    width: calc(100% - 31vw);
    height: 100%;
    background: #edf4f1;
    position: absolute;
    top: 0;
    left: 50%;

    @media (min-width: 1200px) {
      transform: translateX(-50%);
    }
  }

  ${({ variant }) => {
    switch (variant) {
      case '2items':
        return css`
          margin: 7.5rem auto;
          padding-right: 11.667vw;
          padding-left: 11.667vw;
          &:before {
            width: calc(100% - 36.389vw);
            left: 18.194vw;
          }
        `
    }
  }}

  @media (max-width: 1199px) {
    margin-bottom: 5.625rem;
    padding: 3.75rem 0;
    &:before {
      width: 100%;
      left: 0;
    }
  }

  @media (max-width: 1023px) {
    margin: 3.75rem auto;
  }
`

const Title = styled.h3`
  padding: 0 1.9375rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 500;
  font-size: 3.0625rem;
  position: relative;
  z-index: 3;

  @media (max-width: 1023px) {
    font-size: 25px;
  }
`

const Description = styled.div`
  margin-top: 1.125rem;
  padding: 0 1.9375rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 1.75rem;
  position: relative;
  z-index: 3;

  @media (max-width: 1023px) {
    margin-top: 20px;
  }
`

const CTA = styled(Button)`
  margin-top: 3.75rem;
`

const Wrapper = styled.div`
  margin-top: 3.75rem;
  position: relative;
`

const Slider = styled.div`
  position: relative;
  z-index: 2;
`

const Arrows = styled(FlexBox)`
  width: calc(100% + 9.875rem);
  position: absolute;
  top: 50%;
  left: -4.9375rem;
  transform: translateY(-50%);

  > div {
    background: none;
    &:hover {
      background: none;
      svg {
        stroke: #52a186;
      }
    }

    svg {
      stroke: #52a186;
    }
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = '3items' | '2items'
