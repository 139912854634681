import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import LazyLoad from 'react-lazyload'

export interface Props {
  image?: string
  label?: string
  title?: string
}

export const Item = memo(function Item({ image, label, title }: Props) {
  return (
    <Container row>
      <Wrap dial={5}>
        {title ? (
          <Text>
            {label ? <Label>{label}</Label> : null}
            <Title>{title}</Title>
          </Text>
        ) : null}
      </Wrap>
      <Background>
        <LazyLoad>
          <Inner style={{ backgroundImage: `url(${image})` }} />
        </LazyLoad>
      </Background>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 50%;
  grid-column: 1;
  grid-row: 1 / span 2;
  background: ${({ theme }) => theme.colors.variants.neutralDark4};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  text-align: left;
  &:first-of-type {
    background: #54a78b;
    > div {
      height: 100%;
      padding-bottom: 100%;
    }
  }
  &:nth-of-type(3) {
    position: absolute;
    bottom: 0;
    right: 0;
    > div {
      &:first-of-type {
        order: 2;
      }
      &:last-of-type {
        &:before {
          right: -2.25rem;
          left: 50%;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    &:nth-of-type(3) {
      > div {
        &:last-of-type {
          &:before {
            right: -1.5rem;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    &:first-of-type {
      > div {
        padding-bottom: 50%;
      }
    }
    &:nth-of-type(2) {
      > div {
        &:first-of-type {
          order: 2;
        }
      }
    }
    &:nth-of-type(3) {
      position: relative;
      > div {
        &:first-of-type {
          order: 0;
        }
      }
    }
  }
`

const Wrap = styled(FlexBox)`
  width: 50%;
  padding-bottom: 50%;
`

const Text = styled.div`
  padding: 0 3.75rem;
  position: absolute;
  top: 50%;
  left: auto;
  transform: translateY(-50%);
`

const Label = styled.div`
  margin-bottom: 0.5rem;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media (max-width: 767px) {
    margin-bottom: 0.375rem;
    font-size: 9px;
  }
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 500;
  font-size: 1.8125rem;

  @media (max-width: 1023px) {
    font-size: 18px;
  }
`

const Background = styled.div`
  width: 50%;
  padding-bottom: 50%;
  position: relative;
  &:before {
    content: '';
    height: 2px;
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
    position: absolute;
    top: 50%;
    right: 50%;
    left: -2.25rem;
    z-index: 2;
    transform: translateY(-50%);
  }

  @media (max-width: 1023px) {
    &:before {
      left: -1.5rem;
    }
  }

  @media (max-width: 767px) {
    &:before {
      display: none;
    }
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.variants.neutralLight3};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
`
