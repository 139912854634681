import styled from '@emotion/styled'
import { Benefits } from 'app/components/Benefits'
import { Separator } from 'app/components/Common/Separator'
import { EventsSlider } from 'app/components/EventsSlider'
import { Hero } from 'app/components/Hero'
import { Intro } from 'app/components/Intro'
import { Mosaic } from 'app/components/Mosaic'
import { RoomsSlider } from 'app/components/RoomsSlider'
import { SEO } from 'app/components/SEO'
import { ServicesPreview } from 'app/components/ServicesPreview'
import { SocialWall } from 'app/components/SocialWall'
import { Sticker } from 'app/components/Sticker'
import { Footer } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { StructuredData } from 'app/containers/StructuredData'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function HomePageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <SEO {...context.seoProps} />
        {pageContext.headerProps ? (
          <Header
            pageID={pageContext.id}
            languageCode={pageContext.languageCode}
            languagePrefix={pageContext.languagePrefix}
            {...pageContext.headerProps}
          />
        ) : null}
        <GoogleAnalytics />
        {context.heroProps ? <Hero {...context.heroProps} /> : null}
        {context.stickerProps ? <Sticker {...context.stickerProps} /> : null}
        {context.benefitsProps ? <Benefits {...context.benefitsProps} /> : null}
        {context.introProps ? <Intro {...context.introProps} /> : null}
        <Media lessThan="ipadHorizontal">
          <Separator />
        </Media>
        {context.servicesPreviewProps ? (
          <ServicesPreview {...context.servicesPreviewProps} />
        ) : null}
        <Separator />
        {context.roomsSliderProps ? (
          <RoomsSlider {...context.roomsSliderProps} />
        ) : null}
        <Separator />
        {context.mosaicProps ? <Mosaic {...context.mosaicProps} /> : null}
        <Separator />
        {context.eventsSliderProps ? (
          <EventsSlider {...context.eventsSliderProps} />
        ) : null}
        <Media lessThan="ipadHorizontal">
          <Separator />
        </Media>
        {context.socialWallProps ? (
          <SocialWall {...context.socialWallProps} />
        ) : null}
        <Footer
          languageCode={pageContext.languageCode}
          languagePrefix={pageContext.languagePrefix}
        />
        <StructuredData languageCode={pageContext.languageCode} />
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.main``
