import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  cta?: ButtonProps
  label?: string
  services?: ServiceProps[]
  title?: string
}

export const ServicesPreview = memo(function ServicesPreview({
  cta,
  label,
  services,
  title,
}: Props) {
  if (!services) {
    return null
  }

  return (
    <Container>
      <Services row wrap stretch>
        {services.slice(0, 1).map((item, index) => (
          <Service key={index} {...item} />
        ))}
        <Wrapper dial={5}>
          {label ? (
            <FadeInUp>
              <Label>{label}</Label>
            </FadeInUp>
          ) : null}
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
        </Wrapper>
        {services.slice(1, services.length).map((item, index) => (
          <Service key={index} {...item} />
        ))}
      </Services>
      {cta ? <CTA {...cta} variant="corners" /> : null}
    </Container>
  )
})

const Container = styled.section`
  margin: 10rem auto 6.25rem;
  padding: 3.75rem 8.5vw 4.0625rem;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    height: 100%;
    border: 3px solid #edf4f1;
    position: absolute;
    top: 0;
    right: 15.5vw;
    left: 15.5vw;
  }

  @media (max-width: 1199px) {
    padding-right: 1.9375rem;
    padding-left: 1.9375rem;
  }

  @media (max-width: 1023px) {
    margin: 3.75rem auto;
    padding: 1.875rem 0 3.75rem;
    &:before {
      right: 1.9375rem;
      left: 1.9375rem;
    }
  }
`

const Wrapper = styled(FlexBox)`
  width: 50%;
  padding: 0 3.75rem;

  @media (max-width: 1023px) {
    width: 100%;
    margin: 2.5rem auto 4rem;
    padding: 0 65px;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 500;
  font-size: 3.0625rem;
  text-align: center;

  @media (max-width: 1399px) {
    font-size: 32px;
  }

  @media (max-width: 1023px) {
    font-size: 25px;
  }
`

const Title = styled.h2`
  margin-top: 1.0625rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 0.9375rem;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
`

const Services = styled(FlexBox)`
  margin-bottom: -18px;
  margin-left: -18px;

  @media (max-width: 1200px) {
    margin-bottom: -15px;
    margin-left: -15px;
  }
`

const CTA = styled(Button)`
  margin: 3.4375rem auto 0;
`
