import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { motion } from 'framer-motion'
import { uniq } from 'lodash'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  images: ImageProps[]
  label?: string
  title?: string
}

export const SocialWall = memo(function SocialWall({
  label,
  images,
  title,
}: Props) {
  if (!images) {
    return null
  }

  const [ref, isVisible] = useInView({ threshold: 0.25, triggerOnce: true })

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  }

  const child = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  }

  return (
    <Container>
      <Wrapper>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
      </Wrapper>
      <Wall
        ref={ref}
        animate={isVisible ? 'show' : 'hidden'}
        variants={container}
      >
        {uniq(images).map((item, index) => (
          <Item key={index} variants={child}>
            <LazyLoadComponent>
              <Image {...item} />
            </LazyLoadComponent>
          </Item>
        ))}
      </Wall>
    </Container>
  )
})

const Container = styled.section`
  margin: 6.75rem auto 9.25rem;
  padding: 0 15.5vw 1.25rem;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    height: 100%;
    border: 3px solid #edf4f1;
    position: absolute;
    top: 2.25rem;
    right: 11.5vw;
    left: 11.5vw;
  }

  @media (max-width: 1199px) {
    padding-right: 1.9375rem;
    padding-left: 1.9375rem;
    &:before {
      border-right: 0;
      border-left: 0;
      right: 0;
      left: 0;
    }
  }

  @media (max-width: 1023px) {
    margin: 3.75rem auto 6rem;
    &:before {
      display: none;
    }
  }
`

const Wrapper = styled.div`
  width: 554px;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: auto;
  position: relative;
  z-index: 2;

  @media (max-width: 1023px) {
    width: auto;
  }
`

const Label = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 500;
  font-size: 3.0625rem;
  margin-bottom: 1rem;

  @media (max-width: 1023px) {
    font-size: 25px;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 0.9375rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`

const Wall = styled(motion.div)`
  margin-top: 3rem;
  margin-right: -1rem;
  overflow: hidden;

  @media (max-width: 1023px) {
    margin-top: 3.75rem;
    margin-right: -0.5rem;
  }
`

const Item = styled(motion.div)`
  float: left;
  width: calc(25% - 1rem);
  margin-top: 1.1rem;
  margin-right: 1rem;
  padding-bottom: calc(25% - 1rem);
  position: relative;
  &:nth-of-type(8n + 1) {
    width: calc(50% - 1rem);
    padding-bottom: calc(50% - 1rem);
  }
  &:nth-of-type(8n + 8) {
    width: calc(50% - 1rem);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    font-size: 1.875rem;
    line-height: 2.4375rem;
    width: calc(25% - 0.5rem);
    margin-top: 0.6rem;
    margin-right: 0.5rem;
    padding-bottom: calc(25% - 0.5rem);
    &:nth-of-type(8n + 1) {
      width: calc(50% - 0.5rem);
      padding-bottom: calc(50% - 0.5rem);
    }
    &:nth-of-type(8n + 8) {
      width: calc(50% - 0.5rem);
    }
  }
`
