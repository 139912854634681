import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'

export interface Props {
  image?: ImageProps
  title: string
  weight?: number
}

export const Room = memo(function Room({ image }: Props) {
  return (
    <Container>
      {image ? <Background className="swiper-lazy" {...image} /> : null}
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 73.33vh;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 767px) {
    height: 66.66vh;
  }
`

const Background = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`
